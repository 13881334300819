import axios from '@/axios.js'

const state = () => ({
    optionss: [],
})

const mutations = {
  SET_OPTIONSS (state, payload) {
    state.optionss = []
    state.optionss = payload
  },
  ADD_OPTIONS (state, item) {
    state.optionss.unshift(item)
  },
  UPDATE_OPTIONS (state, options) {
    const optionsIndex = state.optionss.findIndex((p) => p.id === options.id)
    Object.assign(state.optionss[optionsIndex], options)
  },
  REMOVE_OPTIONS (state, itemId) {
    const ItemIndex = state.optionss.findIndex((p) => p.id === itemId)
    state.optionss.splice(ItemIndex, 1)
  },
}

const actions = {
  fetchOptions ({ commit }) {

    return new Promise((resolve, reject) => {
      axios.get('/options-list')
        .then((response) => {
          commit('SET_OPTIONSS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addOptions ({ commit }, options ) {
    return new Promise((resolve, reject) => {
      axios.post('/options-add', options)
        .then((response) => {
          commit('ADD_OPTIONS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateOptions ({ commit }, options) {
    return new Promise((resolve, reject) => {
      axios.post(`/options-update/${options.id}`, options)
        .then((response) => {
          commit('UPDATE_OPTIONS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeOptions ({ commit }, optionsId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/options-delete/${optionsId}`)
        .then((response) => {
          commit('REMOVE_OPTIONS', optionsId)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}